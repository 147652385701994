

.product-screen-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1em;
    margin-bottom: 1em;
    width: 100%;
    //border-bottom: lightgrey 1px solid;
    font-family: century-gothic, sans-serif;
    /*background-color: rgba(177, 216, 229, 0.2);*/
    background-color: rgba(255, 255, 255, .3);
    color: black;
    padding: 1em;
    color: #003399;
}

.product-screen-title {
    font-family: century-gothic, sans-serif;
    font-size: 150%;
    font-weight: bolder;
    text-align: left;
}

.product-screen-description {
    font-family: century-gothic, sans-serif;
    font-size: 100%;
    padding-top: 1em;
    /*padding-bottom: 1em;*/
}

.product-screen-description-label {
    font-weight: bolder;
}

.product-screen-description-text {
    padding-left: 1rem;
}

.product-screen-price {
    font-family: century-gothic, sans-serif;
}

.product-screen-queue-link {
    margin-top: 5px;
    margin-bottom: 0em;
    color: black;
}

.product-screen-action-button {
    display: flex;
    width: 100%;
    justify-content: center;
    //background-color: #fef0b4;
    //border: 1px solid lightgrey;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    margin-bottom: 1em;
    margin-top: 1rem;
    /*border-radius: .25rem;*/
    //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.product-screen-member-block {
    color: black;
    width: 100%;
    text-align: center;
    padding: 4px;
    /*color: #045460;*/
    /*color: #ffffff;*/
    border-radius: .25em;
    font-weight: bold;
}

.product-screen-nonmember-block {
    color: #f05d0a;
    width: 100%;
    text-align: center;
    padding: 4px;
    /*color: #ffffff;*/
    border-radius: .25em;
}

.product-screen-top-info-block {
    background-color: black;
    color: white;
    //border-radius: .25em;
    padding: 3px;
    font-weight: bolder;
}

tr.border_bottom td {
    border-bottom:1pt solid black;
}

.product-screen-alert-purchase {
    font-family: century-gothic, sans-serif;
    font-size: small;
}

.product-screen-product-block {
    background-color: white;
    padding: 1rem;
    border: 1px solid #003399;
    color: #003399;

}