.authscreens-alert {
    border: lightgrey 1px solid;
}

.authscreens-alert-message {
    margin-bottom: 0em;
}


.authscreens-card-header {
    background-color: #003399;
    color: white;
    padding: 10px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
}