.accountscreen {
    font-family: century-gothic, sans-serif;
}

.accountscreen-heading {
    font-size: xx-large;
}

.accountscreen-section-heading {
    margin-top: 2rem;
    border-bottom: 1px solid lightgrey;
    font-size: larger;
    font-weight: bold;
}

.accountscreen-default-source {
    font-weight: bolder;
    padding: 20px;
}