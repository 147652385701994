
/* Important part */
.shippingaddressmodal-modal-dialog{
    overflow-y: initial !important;
    width: 90vw;
    max-width: 1000px;
}
.shippingaddressmodal-modal-body{
    height: 60vh;
    overflow-y: auto;
}
