body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/*.App {*/
  /*text-align: center;*/
/*}*/

/*.App-logo {*/
  /*animation: App-logo-spin infinite 20s linear;*/
  /*height: 40vmin;*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/


body {
  background-color: #FEFEFE
  /*background-image: url("img/dots-background.gif");*/
  /*background-repeat: repeat;*/
}


.epiqueueapp {
  /*background-image: url("img/a59b9e4e.png");*/
  /*background-repeat: repeat;*/

  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.epiqueuecontainer {
  padding-top: 10px;

  /*flex: 1;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*background-color: magenta;*/
}

.epiqueue-font {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.epiqueue-font-bold {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bolder;
}

.epiqueue-yellow {
  background-color: #ffda28;
}

.btn-epiqueue {
  background-color: #003399;
  border-color: #003399;
  font-weight: bold;
}

.btn-outline-epiqueue {
  color: #003399;
  border-color: #003399;
}


.epiqueue-blue {
  background-color: #000cff;
}

.epiqueue-lightblue {
  background-color: #d0d9ec;
}

.epiqueue-darkblue {
  background-color: #003399;
}

.bg-darkblue {
  background-color: #003399;
}

.epiqueue-darkblue-text {
  color: #003399;
}

.epiqueue-darkblue-border {
  border: 1px solid #003399;
}

.epiqueue-darkblue-border-top {
  border-top: 1px solid #003399;
}
.epiqueue-darkblue-border-left {
  border-left: 1px solid #003399;
}
.epiqueue-darkblue-border-right {
  border-right: 1px solid #003399;
}
.epiqueue-darkblue-border-bottom {
  border-bottom: 1px solid #003399;
}


.epiqueue-faded-background {
  /*background-color: rgba(00, 33, 99, .1);*/
  background-color: rgba(255, 255, 255, .3);
}

/*.epiqueue-lightblue {*/
/*  background-color: #6470ff !important;*/
/*}*/

.epiqueuefooter {
  text-align: center;
  padding-bottom: 20px;
}

.profilepic {
  //background-color: #000cff;
  color: white;
  border: gray 1px solid;
  //box-shadow: 0px 0px 2px rgba(90, 90, 90, 0.5);
}

.empyprofilepic {
  //background-color: #000cff;
  background-color: gray;
  color: white;
  border: gray 1px solid;
//box-shadow: 0px 0px 2px rgba(90, 90, 90, 0.5);
}




.page-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.sidebarcontainer {
  margin-bottom: 20px;
  width: 18rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-arrow {
  cursor: default;
}

.epiqueue-breadcrumb {
  background-color: white;
  font-family: "Arial";
}

.breadcrumb {
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.sidebar-header {
  font-weight: bolder;
  //text-align: center;
  background-color: rgba(0, 100, 255, 0.5);
  color: black;
  padding-bottom: 2px;
  padding-top: 2px;
  /*background-color: rgba(255,218,40, 0.1);*/
}

.oneline-ellipsis {
  display:block;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deleted {
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.1);
}

.deleted.deleted-active {
  opacity: .0;
  transition: opacity 750ms ease-in, height 750ms linear;
}

.deleted-done {
  opacity: 0;
}

.card-header {
  background-color: rgba(255,218,40, 0.1);
  /*background-color: rgba(0,0,255, .4);*/
}

.tab-container {
  background-color: white;
}

.nav-tabs > li > div.active {
  font-weight: bold;
}

.profilePicInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.profilePicInput + label {
  cursor: pointer; /* "hand" cursor */
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

/*.profilePicInput:focus + label,*/
/*.profilePicInput + label:hover {*/
  /*background-color: red;*/
  /*font-weight: bold;*/
/*}*/

.badge {

}

/*.dropdown-toggle::after {*/
  /*display:none;*/
/*}*/


.alert-badge {
  font-size: .4em;
  float: right;
  margin-bottom: 0px;
  margin-left: -10px;
  z-index: 10;
  position: absolute;
}


.switcheroo-item {
  font-size: larger;
  color: gray;
  text-align: center;
  justify-content: center;
  border-bottom: 1px lightgray solid;
}

.switcheroo-item.active {
  color: black;
  font-weight: bold;
  border-bottom: 3px black solid;
}


.background-blue {
  background-color: #d0d9ec;
}

.background-yellow {
  background-color: yellow;
}

/*search bar*/
.input-group.md-form.form-sm.form-1 input{
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.modal-backdrop, .modal-backdrop.fade.in
{
  opacity:0.5;
}

.modal {
  text-align: center;
}

/*@media screen and (min-width: 768px) {*/
/*  .modal:before {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    content: " ";*/
/*    height: 100%;*/
/*  }*/
/*}*/

.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.busy-modal {
}

.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.four0four-image {
  width:400px;
  height: auto;

}


.image-uploader {
  //background-color: white;
  //border: 1px solid black;

}

.image-upload-cell {
  width: 200px;
  height: 200px;
  /*background-color: black;*/
  border: 1px solid lightgrey;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);*/
}

.image-upload-cell-add {
  width: 200px;
  height: 200px;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);*/
}

.image-upload-cell-add:hover {
  font-weight: bold;
  background-color: gray;
  color:white;
}

.image-upload-cell-trash {
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  opacity: .75%;
}

.image-uploader-image {
  width:100%;
  height:100%;
  object-fit: cover;
  overflow: hidden;
}


.object-fit-contain {
  object-fit: contain;
}

.epiqueue-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #999999;
  margin: 0px;
  padding: 0;

}

.epiqueue-link {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #003399;

}

.epiqueue-brand-link {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #003399;
  font-size: 200%;
}

.epiqueue-page-item {
}

.epiqueue-page-item-disabled {

}

.epiqueue-page-link {
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
}

.epiqueue-page-link-disabled {
  margin-right: 3px;
  margin-left: 3px;
  cursor: default;
  color: lightgrey;
}

.legal {
  text-wrap: normal;
  /*white-space: pre-line;*/
  /*font-family: "Courier New", Courier, monospace;*/
}

.font-150 {
  font-size: 150%;
}


/*.navbar .dropdown-menu {*/
  /*background-color: #ffda28;*/
  /*color: blue;*/
/*}*/

/*.navbar .dropdown-menu li > a:hover, .dropdown-menu li > a:focus, .dropdown-submenu:hover > a {*/
  /*!*background-color: purple;*!*/
  /*!*background-image: none;*!*/

/*}*/

/*!* and this styles the dropdwon trigger link, when open *!*/
/*.navbar .dropdown.show a {*/
  /*background-color: #d7b200;*/
  /*color: blue;*/
/*}*/

/*.epiqueue-nav-dropdown-link > a:not(.activeLink):hover {*/
  /*background-color: rgba(54, 129, 175, 0.2);*/
/*}*/

.authscreens-alert {
    border: lightgrey 1px solid;
}

.authscreens-alert-message {
    margin-bottom: 0em;
}


.authscreens-card-header {
    background-color: #003399;
    color: white;
    padding: 10px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
}
.queue-screen {
    font-family: century-gothic, sans-serif;
}



.queue-screen-products-area {
    width: 75%;
    padding: 5px;
}

.queue-screen-image-area {
    width: 25%;
    padding: 5px;
}

.queue-screen-members-area {
    margin-top: 0em;
}

.queue-screen-members-area a:hover{
    text-decoration: none !important;
}


/*------- LIVE PRODUCT ---------*/


.queue-screen-liveproduct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    color: #003399;
    cursor: pointer;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}

.queue-screen-liveproduct-name {
    font-size: 125%;
    font-weight: bolder;
    margin-bottom: 1rem;
}

.queue-screen-liveproduct-description {
}

.queue-screen-liveproduct-product-area {
    width: 100%;
    padding: 5px;
}

.queue-screen-liveproduct-image-area {
    width: 75%;
    padding: 5px;
    align-self: center;
}

@media screen and (min-width: 768px) {
    .queue-screen-liveproduct-image-area {
        width: 25%;
        padding: 5px;
    }
    .queue-screen-liveproduct-product-area {
        width: 75%;
        padding: 5px;
    }

    .queue-screen-liveproduct {
        flex-direction: row;
    }

}

.queue-screen-liveproduct-image {
    width: 100%;
}

.queue-screen-liveproduct-price {
    align-self: flex-end;
    justify-self: flex-end;
    font-weight: bolder;
    text-align: left;
}






/*------- HEADER ---------*/

.queue-screen-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 0em;
    width: 100%;
    font-family: century-gothic, sans-serif;
    padding: 10px;
    color: #003399;
}

.queue-screen-header-info-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.queue-screen-header-info-queuename {
    font-size: 200%;
    width: 100%;

}

.queue-screen-header-info-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: #003399 2px solid;
    height: 100%;
    margin-left: 1em;
    padding-left: 1em;
    font-family: century-gothic, sans-serif;
    font-size: medium;
    white-space: pre-line;
}

.queue-screen-header-tags {
    justify-self: flex-end;
}

.queue-screen-header-action-button {
    margin-bottom: 2rem;
}

.queue-screen-header-image {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    object-fit: scale-down;

}

.queue-screen-viewer-image {
    object-fit: contain;
    max-height: 200px;
}

@media screen and (min-width: 768px) {
    .queue-screen-header {
        flex-direction: row;
    }

    .queue-screen-header-image {
        min-width: 20%;
        flex-direction: column;
        object-fit: unset;
        /*background-color: rgba(200, 100, 100, .4);*/
    }

    .queue-screen-viewer-image {
        object-fit: contain;
        max-height: 250px;
    }

    .queue-screen-header-info-area {
        max-width: 80%;
        min-width: 60%;
        /*background-color: rgba(100, 100, 100, .4);*/
    }
}

.queue-screen-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .90;
}

.queue-screen-upcoming-product-sales-title {
    /*color: #003399;*/
    color: black;
    font-size: 200%;
    margin-bottom: 5px;
}


/*d-flex flex-row justify-content-between bg-white border-dark*/

.product-card {
    color: black;
    padding: 10px;
    width: 250px;
    height: 250px;
    max-width: 250px;
    position: relative;
    overflow: hidden;
    border: 1px solid #003399;
}

.product-card:hover .product-card-join-icon {
    display: none;
}

.product-card-vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #003399;
    margin-bottom: 1rem;
    padding: 3px;
    background-color: #f8f9fa!important;
}

.product-card-vertical:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;
}

.product-card-title {
}

.product-card-image-container {
    border: 1px solid lightgrey;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 7px;
}


.product-card-image {
    width: 230px;
    height: 170px;
    object-fit: cover;
}

.product-card-image-vertical-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-card-image-vertical {
    width: 230px;
    align-self: center;
}

@media screen and (min-width: 768px) {
    .product-card-vertical {
        flex-direction: row;
    }

    .product-card-image-vertical {
        align-self: flex-start;
    }

    .product-card-image-vertical-column {
        justify-content: flex-start;
        align-items: flex-start;
        width: unset;
    }
}

.product-card-body {
    color: #003399;
}

.product-card-body-vertical {
    color: #003399;
}


.product-card-queue-name {
    color: black;
    text-overflow: ellipsis;
    font-family: sans-serif;
    text-wrap: none;
}

.product-card-owner-name {
    color: darkslategrey;
    font-size: smaller;
    font-family: sans-serif;

    /*no wrap ellipsis*/
    display:block;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.product-card-product-name {
}
.product-card-product-name-vertical {
    font-weight: bolder;
}

.product-card-product-description-vertical {
    font-weight: normal;
    margin-top: 5px;
}

.product-card-product-price-vertical {
    font-weight: bolder;
    margin-top: 5px;

}

.product-card-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
}

.queue-pic-image {
    object-fit: contain;
}

.queue-pic-image-cover {
    object-fit: cover;

}

.scrollingproductlist-vertical {
}

.scrollingproductlist {
}

.scrollingproductlist-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.scrollingproductlist-title-vertical {
    align-items: center;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    /*color: #003399;*/
    margin-bottom: 1rem;
}

.scrollingproductlist-item {
    border: lightgrey 1px solid;
    background-color: #f8f9fa!important;
    margin-right: 1em;
    margin-bottom: 1em;
}

.scrollingproductlist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #eeeeee !important;
}

.scrollingproductlist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}
.pager {
}


.pager-item {
    color: #003399;
    padding: 0rem;
    margin: 0rem;
    padding-left: 3px;
    padding-right: 3px;
}

.pager-item-selected {
    font-weight: bold;
}

.seller-card {
    color: black;
    padding: 5px;
    width: 140px;
    border: 1px solid #003399;
    position: relative;
    overflow: hidden;
}

.seller-card-small {
    color: black;
    padding: 5px;
    width: 110px;
    position: relative;
    overflow: hidden;
}

.seller-card-image {
    width: 130px;
    height: 130px;
}

.seller-card-image-small {
    width: 100px;
    height: 100px;
}

.seller-card-body {
    color: black;
}

.seller-card-seller-name {
    color: black;
    font-family: sans-serif;
    font-size: smaller;
    margin-top: 5px;
    text-align: center;

}

.seller-card-corner-ribbon-right{
    width: 200px;
    background: firebrick;
    position: absolute;
    top: 15px;
    right: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    opacity: .75;
}


.scrollingsellerlist {
    color: #003399;
}

.scrollingsellerlist-title {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    /*color: #003399;*/
    color: black;
}

.scrollingsellerlist-item {
//box-shadow: none;
    /*border: lightgrey 1px solid;*/
    background-color: #f8f9fa!important;
    margin-bottom: 1em;
    margin-right: 1em;
}

.scrollingsellerlist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;
    /*background-color: rgba(200, 200, 200, .4)*/

}

.scrollingsellerlist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}

.scrollingsellerlist-messageButton {
    margin-bottom: 5px;
    /*width: 100%;*/
    margin-top: 5px;
    margin-bottom: 5px;
}
.tag {
    /*border: 1px solid darkgrey;*/
    /*background-color: lightgrey;*/
    /*border-radius: .2rem;*/
    font-family: century-gothic, sans-serif;
    font-size: 100%;
    padding-left: 5px;
    padding-right: 5px;
    color: #003399;
    align-self: center;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
}


.tag-deletable {
    padding-right: 5px;
}

.tag-remove {

}
.nomatchscreen-heading {
    font-family: century-gothic, sans-serif;

}

.nomatchscreen-text {
    font-family: century-gothic, sans-serif;
}
.profilescreen {
    font-family: century-gothic, sans-serif;
}


.profilescreen-offsetright {
    right: 10px;
    top: 10px;
    position: absolute;
}

.profilescreen-infobox {
    background-color: white;
    color: #003399;
    border: 1px solid #003399;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3rem;
    margin-top: 2rem;

}



.profilescreen-infobox-textarea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
}


.profilescreen-infobox-imagearea {
}


.profilescreen-infobox-actionarea {
    padding: 2rem;
    align-self: flex-start;
}


@media screen and (max-width: 768px) {
    .profilescreen-infobox {
        flex-flow: wrap;
        justify-content: center;
        align-items: flex-start;
    }

    .profilescreen-infobox-textarea {
        align-items: center;
        justify-content: center;
        text-align: center;

    }
}


.profilescreen-username {
    font-size: xx-large;
    font-weight: bolder;
    margin-top: 1rem;
}

.profilescreen-description {
    font-size: larger;
}


.profilescreen-profilepic {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /*background-color: black;*/
}

.fas { display:inline; }

.queue-card {
    color: black;
    padding: 10px;
    width: 250px;
    height: 250px;
    position: relative;
    overflow: hidden;
    border: 1px solid #003399;
}

.queue-card:hover .queue-card-join-icon {
    display: none;
}

.queue-card-title {
}
.queue-card-image-container {
    border: 1px solid lightgrey;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-bottom: 7px;
}

.queue-card-image {
    width: 230px;
    height: 170px;
    object-fit: cover;
}

.queue-card-body {
    color: black;
}

.queue-card-queue-name {
    color: #003399;
    text-overflow: ellipsis;
    font-family: sans-serif;
}

.queue-card-owner-name {
    color: darkslategrey;
    font-size: smaller;
    text-overflow: ellipsis;
    font-family: sans-serif;
}

.queue-card-join-icon {
    padding: 1px;
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
}

.queue-card-join-icon-button {
    //padding: 0px;
    font-size: x-large;
}

.queue-card-join-icon:hover {
    font-size: large;
}

.queue-card-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
}

.queue-card-corner-ribbon-right{
    width: 200px;
    background: firebrick;
    position: absolute;
    top: 15px;
    right: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    opacity: .75;
}

/* The ribbons */

.corner-ribbon{
    width: 200px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky{
    position: fixed;
}

.corner-ribbon.shadow{
    box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
    top: 25px;
    left: -50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white{background: #f0f0f0; color: #555;}
.corner-ribbon.black{background: #333;}
.corner-ribbon.grey{background: #999;}
.corner-ribbon.blue{background: #39d;}
.corner-ribbon.green{background: #2c7;}
.corner-ribbon.turquoise{background: #1b9;}
.corner-ribbon.purple{background: #95b;}
.corner-ribbon.red{background: #e43;}
.corner-ribbon.orange{background: #e82;}
.corner-ribbon.yellow{background: #ec0;}


.scrollingqueuelist {
}


.scrollingqueuelist-item {
    //box-shadow: none;
    border: lightgrey 1px solid;
    background-color: #f8f9fa!important;
    margin-right: 1em;
    margin-bottom: 1em;
}

.scrollingqueuelist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;

}

.scrollingqueuelist-header {
    align-items: center;
}

.scrollingqueuelist-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.scrollingqueuelist-create-link {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    text-wrap: none;
}


.scrollingqueuelist-seemore {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    justify-content: center;
    align-self: center;
}

.scrollingqueuelist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}


.editprofilemodal {

}
.sendmessagemodal {
}

.sendmessagemodal-dialog {
    min-width: 90%;
}

@media screen and (min-width: 768px) {
    .sendmessagemodal-dialog {
        min-width: 50%;
    }
}

.blankbox {
    margin-top: 2rem;
    color: #003399;
    /*border-radius: .5rem;*/
}


.blankbox-title {
    font-weight: bolder;
    font-family: century-gothic, sans-serif;
    background-color: #999999;
    /*border: 1px solid #f5f5f6;*/
    padding: 5px;
    padding-left: 1rem;
    font-size: 100%;
    color: white;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    border-top: 1px solid #9999cc;
    /*border-top-right-radius: .35rem;*/
    /*border-top-left-radius: .35rem;*/

}

.blankbox-text-heading {
    font-family: century-gothic, sans-serif;
    font-size: larger;
}

.blankbox-content {
    padding: 1rem;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
}

.blankbox-content-background-color {
    background-color: rgba(255, 255, 255, .3);
}

.blankbox-text {
    font-family: century-gothic, sans-serif;
}

.blankbox-footer {
    text-align: center;
    color: white;
    padding: 5px;
    /*border: 1px solid #999999;*/
    background-color: #999999;

    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    border-bottom: 1px solid #9999cc;
    /*border-bottom-right-radius: .35rem;*/
    /*border-bottom-left-radius: .35rem;*/

}


.aboutscreen {
    font-family: century-gothic, sans-serif;
}

.aboutscreen-heading {
    font-size: 125%;
    padding-bottom: 5px;
}
.image-viewer {
    width: 100%;
}

.image-viewer-thumbnail-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image-viewer-thumbnail {
    object-fit: cover;
    padding:2px;
    cursor: pointer;
    width: 20%;
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
}

.image-viewer-thumbnail-selected {
    border: 2px solid blue;
}

.image-viewer-primary-image{
    vertical-align: top;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.image-viewer-primary-image:before{
}



.image-viewer-primary-nav {
    align-self: center;
}

.image-viewer-primary-image-internal {
    display: flex;
    align-items: center;
    justify-items: center;
}


.image-viewer-image{
    object-fit: contain;
    height: 300px;
    width: 100%;
    align-self: flex-start;
    object-fit: contain;
}


.image-viewer-chevron-buttons {
    background-color: lightgrey;
    margin: 0px;
    padding: 3px;
    border-radius: 0;
}


.product-screen-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1em;
    margin-bottom: 1em;
    width: 100%;
    //border-bottom: lightgrey 1px solid;
    font-family: century-gothic, sans-serif;
    /*background-color: rgba(177, 216, 229, 0.2);*/
    background-color: rgba(255, 255, 255, .3);
    color: black;
    padding: 1em;
    color: #003399;
}

.product-screen-title {
    font-family: century-gothic, sans-serif;
    font-size: 150%;
    font-weight: bolder;
    text-align: left;
}

.product-screen-description {
    font-family: century-gothic, sans-serif;
    font-size: 100%;
    padding-top: 1em;
    /*padding-bottom: 1em;*/
}

.product-screen-description-label {
    font-weight: bolder;
}

.product-screen-description-text {
    padding-left: 1rem;
}

.product-screen-price {
    font-family: century-gothic, sans-serif;
}

.product-screen-queue-link {
    margin-top: 5px;
    margin-bottom: 0em;
    color: black;
}

.product-screen-action-button {
    display: flex;
    width: 100%;
    justify-content: center;
    //background-color: #fef0b4;
    //border: 1px solid lightgrey;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    margin-bottom: 1em;
    margin-top: 1rem;
    /*border-radius: .25rem;*/
    //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.product-screen-member-block {
    color: black;
    width: 100%;
    text-align: center;
    padding: 4px;
    /*color: #045460;*/
    /*color: #ffffff;*/
    border-radius: .25em;
    font-weight: bold;
}

.product-screen-nonmember-block {
    color: #f05d0a;
    width: 100%;
    text-align: center;
    padding: 4px;
    /*color: #ffffff;*/
    border-radius: .25em;
}

.product-screen-top-info-block {
    background-color: black;
    color: white;
    //border-radius: .25em;
    padding: 3px;
    font-weight: bolder;
}

tr.border_bottom td {
    border-bottom:1pt solid black;
}

.product-screen-alert-purchase {
    font-family: century-gothic, sans-serif;
    font-size: small;
}

.product-screen-product-block {
    background-color: white;
    padding: 1rem;
    border: 1px solid #003399;
    color: #003399;

}

/* Important part */
.shippingaddressmodal-modal-dialog{
    overflow-y: visible !important;
    overflow-y: initial !important;
    width: 90vw;
    max-width: 1000px;
}
.shippingaddressmodal-modal-body{
    height: 60vh;
    overflow-y: auto;
}

.accountscreen {
    font-family: century-gothic, sans-serif;
}

.accountscreen-heading {
    font-size: xx-large;
}

.accountscreen-section-heading {
    margin-top: 2rem;
    border-bottom: 1px solid lightgrey;
    font-size: larger;
    font-weight: bold;
}

.accountscreen-default-source {
    font-weight: bolder;
    padding: 20px;
}
.loggedoutindexview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
}

.loggedoutindexview-small-view {
    display: none;
    width: 25%;
    object-fit: fill;
    object-position: center;
    border: 1px solid #003399;
    margin-right: 5px;
}

.loggedoutindexview-large-view {
    width: 100%;
    object-fit: contain;
    position: relative;
    border: 1px solid #003399;
}

.loggedoutindexview-text-area {
    right: 30px;
    bottom: 40px;
    z-index: 999;

    max-width: 75%;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid mediumblue;
    position: absolute;

    color: #00319c;

}

.loggedoutindexview-small-image {
    object-fit: cover;
}

.loggedoutindexview-large-image {
    object-fit: cover;
}

.loggedoutindexview-register-button {
    position: absolute;
    right: 20px;
    bottom: -20px;
    background-color: #003399;
}

.loggedoutindexview-head {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 150%;
}
.loggedoutindexview-subhead {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 125%;
}

.loggedoutindexview-text {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
}

@media screen and (min-width: 992px) {
    .loggedoutindexview-small-view {
        display: block;
    }
    .loggedoutindexview-large-view {
        width: 75%;
    }
}

.index-screen-search-results {
    border: 1px solid lightgrey;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    padding: 1em;
    margin-left: 0em;
    margin-right: 0em;
    background-color: rgba(255, 255, 255, .3);
}

.index-search-header-title {
    color: #003399;
    font-size: 175%;
    font-weight: bold;
    margin-bottom: 1rem;
}

.index-search-header-close {
    color: #003399;
    font-size: 150%;
    cursor: pointer;
}

.index-search-header-close :hover {
    color: #0056b3;
    cursor: pointer;
}

.index-search-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.index-top-message {
    color: #003399;
    background-color: lightgrey;
}

.index-top-message-link {
    color: #003399;
    font-weight: bold;
}

.index-no-product-image {
    max-height: 150px;
    max-width: 250px;
    width: auto;
    height: auto;
}

.index-section-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.logged-in-indexview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
}

.logged-in-indexview-section-title {
    align-items: flex-start;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.logged-in-indexview-top-message {
    color: #003399;
    background-color: white;
    border: 1px solid lightgrey;
    width: 100%;
}

.logged-in-indexview-top-message-link {
    color: #003399;
    font-weight: bold;
}

.logged-in-indexview-queue-block {
    display: flex;
    flex-direction: column;
    /*border: 1px lightgrey solid;*/
    width: 100%;
    cursor: pointer;
}


.logged-in-indexview-queue-image {
    object-fit: cover;
    height: 200px;
    width: 100%;
}

.logged-in-indexview-description {
    font-size: 1rem;
}

@media screen and (min-width: 768px) {
    .logged-in-indexview-queue-image {
        height: 300px;
    }

    .logged-in-indexview-section-title {
        font-size: 3rem;
    }

    .logged-in-indexview-description {
        font-size: 1.5rem;
    }


}


.searchbar {
}


.searchbar-menu {
    padding: 0rem;
    background-color: white;
    max-width: 100%;
    min-width: 100%;
}

.searchbar-header {
    background-color: #003399;
    color: white;
    font-size: 75%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.searchbar-item {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}


.searchbar-text-input {
    border-top-right-radius: .5rem;
    border-radius: 1rem;
}
.product-pic-image {
    object-fit: contain;
}

.product-pic-image-cover {
    object-fit: cover;
}

.footer {
    background-color: lightgrey;
}

.footer-copyright {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.footer-links {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.epiqueuenav {
    /*background-color: #ffda28;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);*/
    background-color: #003399;
}

nav a {
    /*color: #003399;*/
    color: white;
    font-size: 14pt;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

nav a:hover {
    /*color: #003399;*/
    color: white;
}


.navbar-toggler-icon {
    color: white;
}

.epiqueue-nav-link {
    /*color: #003399;*/
    color: white;
    font-size: 14pt;
    cursor: pointer;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.epiqueue-nav-dropdown-link {
    /*color: #003399;*/
    color: white;
    font-size: 18px;
    cursor: pointer;
    background-color: #003399;
}



.epiqueue-nav-link-selected,
.epiqueue-nav-link-selected:focus {
    text-decoration: underline;
}

.epiqueue-nav-icon-link {
    font-size: 18pt;
    line-height: 29pt;
//align-self: center;
    margin-right: 10px;
}

.epiqueuenav-title {

    font-family: century-gothic, sans-serif;
    padding-top: 5px;
    font-weight: bolder;
    font-size: 18pt;
    padding-left: 5px;
    vertical-align: center;

    position: relative;
    top: 2px;

}

.epiqueue-dropdown-background {
    background-color: #003399;
}
.categorybar {

}



.categorybar-tags {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100%;
    display: none;
}

.categorybar-tag {
    margin-right: 5px;
    margin-left: 5px;
}

.categorybar-search-item {
    min-width: 100%;
}

.categorybar-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


@media screen and (min-width: 991px) {
    .categorybar-search-item {
        min-width: 50%;
    }

    .categorybar-tags {
        display: none;
    }

    .categorybar-content {
        justify-content: flex-end;
    }
}


.helpscreen {
    margin-top: 2rem;
    color: #003399;
}


.helpscreen-title {
    font-weight: bolder;
    font-family: century-gothic, sans-serif;
    background-color: white;
    border: 1px solid #003399;
    padding: 5px;
    padding-left: 1rem;
    font-size: 100%;
}

.helpscreen-text-heading {
    font-family: century-gothic, sans-serif;
    font-size: larger;
}

.helpscreen-content {
    padding: 1rem;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    background-color: rgba(255, 255, 255, .3);
}


.helpscreen-text {
    font-family: century-gothic, sans-serif;
}

.helpscreen-footer {
    background-color: white;
    padding: 1rem;
    border: 1px solid #003399;
}

.helpscreen-footer-address {
    background-color: white;
    padding: 3px;
    padding-left: 1rem;
    border: 1px solid #003399;
    text-align: center;
}

.helpscreen-contactform {
}

.helpscreen-largetitle {
    font-size: 2rem;
}
.helpscreen-subheader {
    font-size: 1.5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.helpscreen-paragraph {
    font-size: 1rem;
    margin-left: 1rem;
}
.messagesscreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.messagesscreen-no-messages {
    margin-top: 1em;
    margin-bottom: 1em;
    align-items: center;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 24pt;
    text-align: center;
}

.messagesscreen-no-messages-image {
    text-align: center;
}

.messagesscreen-left-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    margin-bottom: 2rem;

}

.messagesscreen-left-nav-items {
    /*margin-top: 1rem;*/
    border: 1px solid #003399;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
}


.messagesscreen-left-nav-item {
    font-family: 'FontAwesome', century-gothic, sans-serif;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    /*padding-top: 3px;*/
    cursor: pointer;
    margin-bottom: 3px;
    margin-top: 3px;

}

.messagesscreen-left-nav-item-selected {
    background-color: #f0f0f0;
}


.messagesscreen-left-search {
    font-family: 'FontAwesome', century-gothic, sans-serif;
    font-size: 100%;
}


.messagesscreen-action-messages-area {
    border: 1px solid #003399;
    width: 100%;
    background-color: white;
}

.messagesscreen-action-table {
    width: 100%;
    color: #003399;
}


.messagesscreen-text-input {
    width: 100%;
    border: 1px solid #003399;
}

.messagesscreen-action-box-area {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.messagesscreen-action-box {
    background-color: white;
    border: 1px solid #003399;
    padding: 5px;
    align-items: center;
    margin-right: 1rem;
    border-radius: .2rem;
}

.messagesscreen-action-box-checkbox {
    padding-left: 10px;
    padding-right: 10px;
}

.messagesscreen-table-row {
    align-items: center;
    text-align: left;
    cursor: pointer;
    vertical-align: middle !important;
}

.messagesscreen-table-cell {
    vertical-align: middle !important;
}

@media screen and (min-width: 768px) {
    .messagesscreen {
        flex-direction: row;
    }
    .messagesscreen-left-nav {
        width: 250px;
        margin-right: 2rem;
    }
}

.newaccountscreen-font {
    font-family: century-gothic, sans-serif;
}

.newaccountscreen-heading {
    font-size: xx-large;
    margin-bottom: 2rem;
}

.newaccountscreen-step-card {
    background-color: white;
    padding: 1rem;
    border: black 1px solid;
}

.newaccountscreen-cover {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #ddd;
    opacity: 0.8;
    overflow: hidden;
}

.newaccountscreen-checkbox {
    color: mediumseagreen;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.stripecreditcardform {
    border: 1px solid #003399;
    box-shadow: 0px 8px 3px rgba(0, 0, 0, 0.4);
}
.cartscreen-header {
    font-family: century-gothic, sans-serif;
}
.row-bordered:after {
    content: "";
    display: block;
    border-bottom: 1px solid #003399;
    margin: 0 15px;
}


.checkoutscreen-table {
    border-bottom: 1px solid #003399;
    border-top: 1px solid #003399;

}

.checkoutscreen-order-table {
    border-bottom: 1px solid #003399;
}

.checkoutscreen-footer {
    border-bottom: 1px solid #003399;
    border-right: 1px solid #003399;
    border-left: 1px solid #003399;
    padding: 1rem;
}

.checkoutscreen-order-row {
    background-color: lightgrey;
    border-top: 1px solid #003399 !important;
}

.checkoutscreen-order-row:after {
    display: block;
    border-top: 1px solid #003399 !important;
}


.messagescreen-message-area {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    background-color: white;
}


@media screen and (min-width: 700px) {
    .messagescreen-message-area {
        min-width: 600px;
    }
}

.unsubscribescreen-title {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: x-large;
    text-align: center;
    margin-top: 3rem;
}

.unsubscribescreen-text {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: large;
    text-align: center;
    margin-top: 1rem;
}
.receiptscreen-table-header {
    background-color: white;
    border: 1px solid #003399 !important;
}

.receiptscreen-table-body {
    /*border: 1px solid #003399;*/
    border-left: 1px solid #a4b5d8;
    border-right: 1px solid #a4b5d8;

}
.receiptscreen-table {
}

.receiptscreen-table-footer {
    background-color: white;
    border: 1px solid #003399 !important;
    font-weight: bold;
}

.receiptscreen-table-cell {
    border: 1px solid #003399;

}

.receiptscreen-table-border-row {
    border: 1px solid #003399 !important;

}



/*.dashboard-screen-card {*/
/*//box-shadow: none;*/
    /*border: lightgrey 1px solid;*/
    /*background-color: #f8f9fa!important;*/
    /*margin: 3px;*/
    /*margin-right: 2em;*/
/*}*/

/*.dashboard-screen-card:hover {*/
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
    /*background-color: #efefef !important;*/
/*}*/


.dashboard-screen-create-link {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    text-wrap: none;
}


.dashboard-screen-title {
    font-size: 150%;
    font-family: century-gothic, sans-serif;
    color: #003399;
}

.dashboard-screen-card-header {
    font-family: century-gothic, sans-serif;
    font-size: 18pt;
    color: #003399;
}

.dashboard-screen-card-deck {
}

.dashboard-screen-card {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    position: relative;
    overflow: hidden;
}


.dashboard-screen-card-pricing {
    font-family: century-gothic, sans-serif;
    text-align: center;
    color: #003399;
    font-size: larger;
    padding-bottom: 1rem;
}

.dashboard-screen-heading {
    font-family: century-gothic, sans-serif;
    font-size: 14pt;
    text-align: justify-all;
    padding: 1rem;
}

.dashboard-settings-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    background-color: white;
    padding: 1rem;
    border: solid 1px #003399;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-stats-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 3rem;
    background-color: white;
    border: solid 1px #003399;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-stats-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    min-height: 100px;
    padding: 1rem;
    text-align: center;
    color: #003399;
    align-items: center;
    justify-content: center;
}

.dashboard-stats-box-right-border {
    border-right: 1px solid #003399;
}

.dashboard-stats-box-left-border {
    border-left: 1px solid #003399;
}

.dashboard-stats-box-label {
    font-size: 75%;
    font-weight: bolder;
}

.dashboard-stats-box-value {
    font-size: 100%;
    font-weight: bolder;
}

.dashboard-current-stats-card {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    margin-bottom: 3rem;
    align-items: flex-start;
    justify-content: space-between;
    color: #003399;
}

.dashboard-current-stats-box {
    background-color: white;
    border: solid 1px #003399;
    width: 100%;
}

.dashboard-current-stats-box-right {
    margin-top: 1rem;
}

@media screen and (min-width: 768px) {
    .dashboard-stats-box-value {
        font-size: 125%;
    }
}

@media screen and (min-width: 1200px) {
    .dashboard-current-stats-box {
        width: 49%;
    }
    .dashboard-current-stats-box-right {
        margin-top: 0rem;
    }
    .dashboard-stats-box-value {
        font-size: 150%;
    }
}



.dashboard-ribbon{
    width: 210px;
    background: firebrick;
    position: absolute;
    top: 40px;
    left: -45px;
    text-align: center;
    line-height: 36px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
    font-weight: bold;
}



.dashboard-screen-queueinfo {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 1px black;
    width: 100%;
    margin-bottom: 1em;
}

.dashboard-queue-screen-card {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
    align-self: center;
    position: relative;
    overflow: hidden;
}

.dashboard-queue-button-area {
}


.dashboard-screen-table-area {
    width: 100%;
}

.dashboard-screen-table {
    width: 100%;
}

@media screen and (min-width: 992px) {
    .dashboard-screen-queueinfo {
        flex-direction: row;
        align-self: flex-start;
    }
    .dashboard-screen-table-area {
    }
}

.orderscreen-table-label {
    font-size: 150%;

}
.orderlistscreen {
    color: #003399;
}

.orderlistscreen-orderbox {
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
}


.orderlistscreen-table {
    background-color: white;
}
.shippinginfo-modal {
    /*background-color: #003399;*/
}

.shippinginfo-modal-dialog {
    min-width: 50%;
}
