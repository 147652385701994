.messagesscreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.messagesscreen-no-messages {
    margin-top: 1em;
    margin-bottom: 1em;
    align-items: center;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 24pt;
    text-align: center;
}

.messagesscreen-no-messages-image {
    text-align: center;
}

.messagesscreen-left-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    margin-bottom: 2rem;

}

.messagesscreen-left-nav-items {
    /*margin-top: 1rem;*/
    border: 1px solid #003399;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
}


.messagesscreen-left-nav-item {
    font-family: 'FontAwesome', century-gothic, sans-serif;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    /*padding-top: 3px;*/
    cursor: pointer;
    margin-bottom: 3px;
    margin-top: 3px;

}

.messagesscreen-left-nav-item-selected {
    background-color: #f0f0f0;
}


.messagesscreen-left-search {
    font-family: 'FontAwesome', century-gothic, sans-serif;
    font-size: 100%;
}


.messagesscreen-action-messages-area {
    border: 1px solid #003399;
    width: 100%;
    background-color: white;
}

.messagesscreen-action-table {
    width: 100%;
    color: #003399;
}


.messagesscreen-text-input {
    width: 100%;
    border: 1px solid #003399;
}

.messagesscreen-action-box-area {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.messagesscreen-action-box {
    background-color: white;
    border: 1px solid #003399;
    padding: 5px;
    align-items: center;
    margin-right: 1rem;
    border-radius: .2rem;
}

.messagesscreen-action-box-checkbox {
    padding-left: 10px;
    padding-right: 10px;
}

.messagesscreen-table-row {
    align-items: center;
    text-align: left;
    cursor: pointer;
    vertical-align: middle !important;
}

.messagesscreen-table-cell {
    vertical-align: middle !important;
}

@media screen and (min-width: 768px) {
    .messagesscreen {
        flex-direction: row;
    }
    .messagesscreen-left-nav {
        width: 250px;
        margin-right: 2rem;
    }
}
