.profilescreen {
    font-family: century-gothic, sans-serif;
}


.profilescreen-offsetright {
    right: 10px;
    top: 10px;
    position: absolute;
}

.profilescreen-infobox {
    background-color: white;
    color: #003399;
    border: 1px solid #003399;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3rem;
    margin-top: 2rem;

}



.profilescreen-infobox-textarea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
}


.profilescreen-infobox-imagearea {
}


.profilescreen-infobox-actionarea {
    padding: 2rem;
    align-self: flex-start;
}


@media screen and (max-width: 768px) {
    .profilescreen-infobox {
        flex-flow: wrap;
        justify-content: center;
        align-items: flex-start;
    }

    .profilescreen-infobox-textarea {
        align-items: center;
        justify-content: center;
        text-align: center;

    }
}


.profilescreen-username {
    font-size: xx-large;
    font-weight: bolder;
    margin-top: 1rem;
}

.profilescreen-description {
    font-size: larger;
}


.profilescreen-profilepic {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /*background-color: black;*/
}

.fas { display:inline; }