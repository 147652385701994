
.epiqueuenav {
    /*background-color: #ffda28;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);*/
    background-color: #003399;
}

nav a {
    /*color: #003399;*/
    color: white;
    font-size: 14pt;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

nav a:hover {
    /*color: #003399;*/
    color: white;
}


.navbar-toggler-icon {
    color: white;
}

.epiqueue-nav-link {
    /*color: #003399;*/
    color: white;
    font-size: 14pt;
    cursor: pointer;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.epiqueue-nav-dropdown-link {
    /*color: #003399;*/
    color: white;
    font-size: 18px;
    cursor: pointer;
    background-color: #003399;
}



.epiqueue-nav-link-selected,
.epiqueue-nav-link-selected:focus {
    text-decoration: underline;
}

.epiqueue-nav-icon-link {
    font-size: 18pt;
    line-height: 29pt;
//align-self: center;
    margin-right: 10px;
}

.epiqueuenav-title {

    font-family: century-gothic, sans-serif;
    padding-top: 5px;
    font-weight: bolder;
    font-size: 18pt;
    padding-left: 5px;
    vertical-align: center;

    position: relative;
    top: 2px;

}

.epiqueue-dropdown-background {
    background-color: #003399;
}