.sendmessagemodal {
}

.sendmessagemodal-dialog {
    min-width: 90%;
}

@media screen and (min-width: 768px) {
    .sendmessagemodal-dialog {
        min-width: 50%;
    }
}
