.loggedoutindexview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
}

.loggedoutindexview-small-view {
    display: none;
    width: 25%;
    object-fit: fill;
    object-position: center;
    border: 1px solid #003399;
    margin-right: 5px;
}

.loggedoutindexview-large-view {
    width: 100%;
    object-fit: contain;
    position: relative;
    border: 1px solid #003399;
}

.loggedoutindexview-text-area {
    right: 30px;
    bottom: 40px;
    z-index: 999;

    max-width: 75%;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid mediumblue;
    position: absolute;

    color: #00319c;

}

.loggedoutindexview-small-image {
    object-fit: cover;
}

.loggedoutindexview-large-image {
    object-fit: cover;
}

.loggedoutindexview-register-button {
    position: absolute;
    right: 20px;
    bottom: -20px;
    background-color: #003399;
}

.loggedoutindexview-head {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 150%;
}
.loggedoutindexview-subhead {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 125%;
}

.loggedoutindexview-text {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
}

@media screen and (min-width: 992px) {
    .loggedoutindexview-small-view {
        display: block;
    }
    .loggedoutindexview-large-view {
        width: 75%;
    }
}
