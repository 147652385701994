/*.App {*/
  /*text-align: center;*/
/*}*/

/*.App-logo {*/
  /*animation: App-logo-spin infinite 20s linear;*/
  /*height: 40vmin;*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/


body {
  background-color: #FEFEFE
  /*background-image: url("img/dots-background.gif");*/
  /*background-repeat: repeat;*/
}


.epiqueueapp {
  /*background-image: url("img/a59b9e4e.png");*/
  /*background-repeat: repeat;*/

  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.epiqueuecontainer {
  padding-top: 10px;

  /*flex: 1;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*background-color: magenta;*/
}

.epiqueue-font {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.epiqueue-font-bold {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bolder;
}

.epiqueue-yellow {
  background-color: #ffda28;
}

.btn-epiqueue {
  background-color: #003399;
  border-color: #003399;
  font-weight: bold;
}

.btn-outline-epiqueue {
  color: #003399;
  border-color: #003399;
}


.epiqueue-blue {
  background-color: #000cff;
}

.epiqueue-lightblue {
  background-color: #d0d9ec;
}

.epiqueue-darkblue {
  background-color: #003399;
}

.bg-darkblue {
  background-color: #003399;
}

.epiqueue-darkblue-text {
  color: #003399;
}

.epiqueue-darkblue-border {
  border: 1px solid #003399;
}

.epiqueue-darkblue-border-top {
  border-top: 1px solid #003399;
}
.epiqueue-darkblue-border-left {
  border-left: 1px solid #003399;
}
.epiqueue-darkblue-border-right {
  border-right: 1px solid #003399;
}
.epiqueue-darkblue-border-bottom {
  border-bottom: 1px solid #003399;
}


.epiqueue-faded-background {
  /*background-color: rgba(00, 33, 99, .1);*/
  background-color: rgba(255, 255, 255, .3);
}

/*.epiqueue-lightblue {*/
/*  background-color: #6470ff !important;*/
/*}*/

.epiqueuefooter {
  text-align: center;
  padding-bottom: 20px;
}

.profilepic {
  //background-color: #000cff;
  color: white;
  border: gray 1px solid;
  //box-shadow: 0px 0px 2px rgba(90, 90, 90, 0.5);
}

.empyprofilepic {
  //background-color: #000cff;
  background-color: gray;
  color: white;
  border: gray 1px solid;
//box-shadow: 0px 0px 2px rgba(90, 90, 90, 0.5);
}




.page-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.sidebarcontainer {
  margin-bottom: 20px;
  width: 18rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-arrow {
  cursor: default;
}

.epiqueue-breadcrumb {
  background-color: white;
  font-family: "Arial";
}

.breadcrumb {
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.sidebar-header {
  font-weight: bolder;
  //text-align: center;
  background-color: rgba(0, 100, 255, 0.5);
  color: black;
  padding-bottom: 2px;
  padding-top: 2px;
  /*background-color: rgba(255,218,40, 0.1);*/
}

.oneline-ellipsis {
  display:block;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deleted {
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.1);
}

.deleted.deleted-active {
  opacity: .0;
  transition: opacity 750ms ease-in, height 750ms linear;
}

.deleted-done {
  opacity: 0;
}

.card-header {
  background-color: rgba(255,218,40, 0.1);
  /*background-color: rgba(0,0,255, .4);*/
}

.tab-container {
  background-color: white;
}

.nav-tabs > li > div.active {
  font-weight: bold;
}

.profilePicInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.profilePicInput + label {
  cursor: pointer; /* "hand" cursor */
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

/*.profilePicInput:focus + label,*/
/*.profilePicInput + label:hover {*/
  /*background-color: red;*/
  /*font-weight: bold;*/
/*}*/

.badge {

}

/*.dropdown-toggle::after {*/
  /*display:none;*/
/*}*/


.alert-badge {
  font-size: .4em;
  float: right;
  margin-bottom: 0px;
  margin-left: -10px;
  z-index: 10;
  position: absolute;
}


.switcheroo-item {
  font-size: larger;
  color: gray;
  text-align: center;
  justify-content: center;
  border-bottom: 1px lightgray solid;
}

.switcheroo-item.active {
  color: black;
  font-weight: bold;
  border-bottom: 3px black solid;
}


.background-blue {
  background-color: #d0d9ec;
}

.background-yellow {
  background-color: yellow;
}

/*search bar*/
.input-group.md-form.form-sm.form-1 input{
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.modal-backdrop, .modal-backdrop.fade.in
{
  opacity:0.5;
}

.modal {
  text-align: center;
}

/*@media screen and (min-width: 768px) {*/
/*  .modal:before {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    content: " ";*/
/*    height: 100%;*/
/*  }*/
/*}*/

.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.busy-modal {
}

.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.four0four-image {
  width:400px;
  height: auto;

}


.image-uploader {
  //background-color: white;
  //border: 1px solid black;

}

.image-upload-cell {
  width: 200px;
  height: 200px;
  /*background-color: black;*/
  border: 1px solid lightgrey;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);*/
}

.image-upload-cell-add {
  width: 200px;
  height: 200px;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);*/
}

.image-upload-cell-add:hover {
  font-weight: bold;
  background-color: gray;
  color:white;
}

.image-upload-cell-trash {
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  opacity: .75%;
}

.image-uploader-image {
  width:100%;
  height:100%;
  object-fit: cover;
  overflow: hidden;
}


.object-fit-contain {
  object-fit: contain;
}

.epiqueue-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #999999;
  margin: 0px;
  padding: 0;

}

.epiqueue-link {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #003399;

}

.epiqueue-brand-link {
  font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #003399;
  font-size: 200%;
}

.epiqueue-page-item {
}

.epiqueue-page-item-disabled {

}

.epiqueue-page-link {
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
}

.epiqueue-page-link-disabled {
  margin-right: 3px;
  margin-left: 3px;
  cursor: default;
  color: lightgrey;
}

.legal {
  text-wrap: normal;
  /*white-space: pre-line;*/
  /*font-family: "Courier New", Courier, monospace;*/
}

.font-150 {
  font-size: 150%;
}


/*.navbar .dropdown-menu {*/
  /*background-color: #ffda28;*/
  /*color: blue;*/
/*}*/

/*.navbar .dropdown-menu li > a:hover, .dropdown-menu li > a:focus, .dropdown-submenu:hover > a {*/
  /*!*background-color: purple;*!*/
  /*!*background-image: none;*!*/

/*}*/

/*!* and this styles the dropdwon trigger link, when open *!*/
/*.navbar .dropdown.show a {*/
  /*background-color: #d7b200;*/
  /*color: blue;*/
/*}*/

/*.epiqueue-nav-dropdown-link > a:not(.activeLink):hover {*/
  /*background-color: rgba(54, 129, 175, 0.2);*/
/*}*/
