.tag {
    /*border: 1px solid darkgrey;*/
    /*background-color: lightgrey;*/
    /*border-radius: .2rem;*/
    font-family: century-gothic, sans-serif;
    font-size: 100%;
    padding-left: 5px;
    padding-right: 5px;
    color: #003399;
    align-self: center;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
}


.tag-deletable {
    padding-right: 5px;
}

.tag-remove {

}