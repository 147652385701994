.image-viewer {
    width: 100%;
}

.image-viewer-thumbnail-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image-viewer-thumbnail {
    object-fit: cover;
    padding:2px;
    cursor: pointer;
    width: 20%;
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
}

.image-viewer-thumbnail-selected {
    border: 2px solid blue;
}

.image-viewer-primary-image{
    vertical-align: top;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.image-viewer-primary-image:before{
}



.image-viewer-primary-nav {
    align-self: center;
}

.image-viewer-primary-image-internal {
    display: flex;
    align-items: center;
    justify-items: center;
}


.image-viewer-image{
    object-fit: contain;
    height: 300px;
    width: 100%;
    align-self: flex-start;
    object-fit: contain;
}


.image-viewer-chevron-buttons {
    background-color: lightgrey;
    margin: 0px;
    padding: 3px;
    border-radius: 0;
}