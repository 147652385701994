
.queue-card {
    color: black;
    padding: 10px;
    width: 250px;
    height: 250px;
    position: relative;
    overflow: hidden;
    border: 1px solid #003399;
}

.queue-card:hover .queue-card-join-icon {
    display: none;
}

.queue-card-title {
}
.queue-card-image-container {
    border: 1px solid lightgrey;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-bottom: 7px;
}

.queue-card-image {
    width: 230px;
    height: 170px;
    object-fit: cover;
}

.queue-card-body {
    color: black;
}

.queue-card-queue-name {
    color: #003399;
    text-overflow: ellipsis;
    font-family: sans-serif;
}

.queue-card-owner-name {
    color: darkslategrey;
    font-size: smaller;
    text-overflow: ellipsis;
    font-family: sans-serif;
}

.queue-card-join-icon {
    padding: 1px;
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
}

.queue-card-join-icon-button {
    //padding: 0px;
    font-size: x-large;
}

.queue-card-join-icon:hover {
    font-size: large;
}

.queue-card-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
}

.queue-card-corner-ribbon-right{
    width: 200px;
    background: firebrick;
    position: absolute;
    top: 15px;
    right: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    opacity: .75;
}
