.unsubscribescreen-title {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: x-large;
    text-align: center;
    margin-top: 3rem;
}

.unsubscribescreen-text {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: large;
    text-align: center;
    margin-top: 1rem;
}