.receiptscreen-table-header {
    background-color: white;
    border: 1px solid #003399 !important;
}

.receiptscreen-table-body {
    /*border: 1px solid #003399;*/
    border-left: 1px solid #a4b5d8;
    border-right: 1px solid #a4b5d8;

}
.receiptscreen-table {
}

.receiptscreen-table-footer {
    background-color: white;
    border: 1px solid #003399 !important;
    font-weight: bold;
}

.receiptscreen-table-cell {
    border: 1px solid #003399;

}

.receiptscreen-table-border-row {
    border: 1px solid #003399 !important;

}
