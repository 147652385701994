
.scrollingproductlist-vertical {
}

.scrollingproductlist {
}

.scrollingproductlist-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.scrollingproductlist-title-vertical {
    align-items: center;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    /*color: #003399;*/
    margin-bottom: 1rem;
}

.scrollingproductlist-item {
    border: lightgrey 1px solid;
    background-color: #f8f9fa!important;
    margin-right: 1em;
    margin-bottom: 1em;
}

.scrollingproductlist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #eeeeee !important;
}

.scrollingproductlist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}