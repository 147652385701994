
.product-card {
    color: black;
    padding: 10px;
    width: 250px;
    height: 250px;
    max-width: 250px;
    position: relative;
    overflow: hidden;
    border: 1px solid #003399;
}

.product-card:hover .product-card-join-icon {
    display: none;
}

.product-card-vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #003399;
    margin-bottom: 1rem;
    padding: 3px;
    background-color: #f8f9fa!important;
}

.product-card-vertical:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;
}

.product-card-title {
}

.product-card-image-container {
    border: 1px solid lightgrey;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 7px;
}


.product-card-image {
    width: 230px;
    height: 170px;
    object-fit: cover;
}

.product-card-image-vertical-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-card-image-vertical {
    width: 230px;
    align-self: center;
}

@media screen and (min-width: 768px) {
    .product-card-vertical {
        flex-direction: row;
    }

    .product-card-image-vertical {
        align-self: flex-start;
    }

    .product-card-image-vertical-column {
        justify-content: flex-start;
        align-items: flex-start;
        width: unset;
    }
}

.product-card-body {
    color: #003399;
}

.product-card-body-vertical {
    color: #003399;
}


.product-card-queue-name {
    color: black;
    text-overflow: ellipsis;
    font-family: sans-serif;
    text-wrap: none;
}

.product-card-owner-name {
    color: darkslategrey;
    font-size: smaller;
    font-family: sans-serif;

    /*no wrap ellipsis*/
    display:block;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.product-card-product-name {
}
.product-card-product-name-vertical {
    font-weight: bolder;
}

.product-card-product-description-vertical {
    font-weight: normal;
    margin-top: 5px;
}

.product-card-product-price-vertical {
    font-weight: bolder;
    margin-top: 5px;

}

.product-card-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
}
