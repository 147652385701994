.messagescreen-message-area {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    background-color: white;
}


@media screen and (min-width: 700px) {
    .messagescreen-message-area {
        min-width: 600px;
    }
}
