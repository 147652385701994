.dashboard-screen-queueinfo {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 1px black;
    width: 100%;
    margin-bottom: 1em;
}

.dashboard-queue-screen-card {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
    align-self: center;
    position: relative;
    overflow: hidden;
}

.dashboard-queue-button-area {
}


.dashboard-screen-table-area {
    width: 100%;
}

.dashboard-screen-table {
    width: 100%;
}

@media screen and (min-width: 992px) {
    .dashboard-screen-queueinfo {
        flex-direction: row;
        align-self: flex-start;
    }
    .dashboard-screen-table-area {
    }
}
