
.searchbar {
}


.searchbar-menu {
    padding: 0rem;
    background-color: white;
    max-width: 100%;
    min-width: 100%;
}

.searchbar-header {
    background-color: #003399;
    color: white;
    font-size: 75%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.searchbar-item {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}


.searchbar-text-input {
    border-top-right-radius: .5rem;
    border-radius: 1rem;
}