.logged-in-indexview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
}

.logged-in-indexview-section-title {
    align-items: flex-start;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.logged-in-indexview-top-message {
    color: #003399;
    background-color: white;
    border: 1px solid lightgrey;
    width: 100%;
}

.logged-in-indexview-top-message-link {
    color: #003399;
    font-weight: bold;
}

.logged-in-indexview-queue-block {
    display: flex;
    flex-direction: column;
    /*border: 1px lightgrey solid;*/
    width: 100%;
    cursor: pointer;
}


.logged-in-indexview-queue-image {
    object-fit: cover;
    height: 200px;
    width: 100%;
}

.logged-in-indexview-description {
    font-size: 1rem;
}

@media screen and (min-width: 768px) {
    .logged-in-indexview-queue-image {
        height: 300px;
    }

    .logged-in-indexview-section-title {
        font-size: 3rem;
    }

    .logged-in-indexview-description {
        font-size: 1.5rem;
    }


}
