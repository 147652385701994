.newaccountscreen-font {
    font-family: century-gothic, sans-serif;
}

.newaccountscreen-heading {
    font-size: xx-large;
    margin-bottom: 2rem;
}

.newaccountscreen-step-card {
    background-color: white;
    padding: 1rem;
    border: black 1px solid;
}

.newaccountscreen-cover {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #ddd;
    opacity: 0.8;
    overflow: hidden;
}

.newaccountscreen-checkbox {
    color: mediumseagreen;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;
}
