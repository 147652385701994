
.scrollingsellerlist {
    color: #003399;
}

.scrollingsellerlist-title {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    /*color: #003399;*/
    color: black;
}

.scrollingsellerlist-item {
//box-shadow: none;
    /*border: lightgrey 1px solid;*/
    background-color: #f8f9fa!important;
    margin-bottom: 1em;
    margin-right: 1em;
}

.scrollingsellerlist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;
    /*background-color: rgba(200, 200, 200, .4)*/

}

.scrollingsellerlist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}

.scrollingsellerlist-messageButton {
    margin-bottom: 5px;
    /*width: 100%;*/
    margin-top: 5px;
    margin-bottom: 5px;
}