.footer {
    background-color: lightgrey;
}

.footer-copyright {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.footer-links {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
}