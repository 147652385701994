.categorybar {

}



.categorybar-tags {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100%;
    display: none;
}

.categorybar-tag {
    margin-right: 5px;
    margin-left: 5px;
}

.categorybar-search-item {
    min-width: 100%;
}

.categorybar-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


@media screen and (min-width: 991px) {
    .categorybar-search-item {
        min-width: 50%;
    }

    .categorybar-tags {
        display: none;
    }

    .categorybar-content {
        justify-content: flex-end;
    }
}

