.orderlistscreen {
    color: #003399;
}

.orderlistscreen-orderbox {
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
}


.orderlistscreen-table {
    background-color: white;
}