.row-bordered:after {
    content: "";
    display: block;
    border-bottom: 1px solid #003399;
    margin: 0 15px;
}


.checkoutscreen-table {
    border-bottom: 1px solid #003399;
    border-top: 1px solid #003399;

}

.checkoutscreen-order-table {
    border-bottom: 1px solid #003399;
}

.checkoutscreen-footer {
    border-bottom: 1px solid #003399;
    border-right: 1px solid #003399;
    border-left: 1px solid #003399;
    padding: 1rem;
}

.checkoutscreen-order-row {
    background-color: lightgrey;
    border-top: 1px solid #003399 !important;
}

.checkoutscreen-order-row:after {
    display: block;
    border-top: 1px solid #003399 !important;
}

