.helpscreen {
    margin-top: 2rem;
    color: #003399;
}


.helpscreen-title {
    font-weight: bolder;
    font-family: century-gothic, sans-serif;
    background-color: white;
    border: 1px solid #003399;
    padding: 5px;
    padding-left: 1rem;
    font-size: 100%;
}

.helpscreen-text-heading {
    font-family: century-gothic, sans-serif;
    font-size: larger;
}

.helpscreen-content {
    padding: 1rem;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    background-color: rgba(255, 255, 255, .3);
}


.helpscreen-text {
    font-family: century-gothic, sans-serif;
}

.helpscreen-footer {
    background-color: white;
    padding: 1rem;
    border: 1px solid #003399;
}

.helpscreen-footer-address {
    background-color: white;
    padding: 3px;
    padding-left: 1rem;
    border: 1px solid #003399;
    text-align: center;
}

.helpscreen-contactform {
}

.helpscreen-largetitle {
    font-size: 2rem;
}
.helpscreen-subheader {
    font-size: 1.5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.helpscreen-paragraph {
    font-size: 1rem;
    margin-left: 1rem;
}