.blankbox {
    margin-top: 2rem;
    color: #003399;
    /*border-radius: .5rem;*/
}


.blankbox-title {
    font-weight: bolder;
    font-family: century-gothic, sans-serif;
    background-color: #999999;
    /*border: 1px solid #f5f5f6;*/
    padding: 5px;
    padding-left: 1rem;
    font-size: 100%;
    color: white;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    border-top: 1px solid #9999cc;
    /*border-top-right-radius: .35rem;*/
    /*border-top-left-radius: .35rem;*/

}

.blankbox-text-heading {
    font-family: century-gothic, sans-serif;
    font-size: larger;
}

.blankbox-content {
    padding: 1rem;
    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
}

.blankbox-content-background-color {
    background-color: rgba(255, 255, 255, .3);
}

.blankbox-text {
    font-family: century-gothic, sans-serif;
}

.blankbox-footer {
    text-align: center;
    color: white;
    padding: 5px;
    /*border: 1px solid #999999;*/
    background-color: #999999;

    border-left: 1px solid #9999cc;
    border-right: 1px solid #9999cc;
    border-bottom: 1px solid #9999cc;
    /*border-bottom-right-radius: .35rem;*/
    /*border-bottom-left-radius: .35rem;*/

}

