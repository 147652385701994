.index-screen-search-results {
    border: 1px solid lightgrey;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    padding: 1em;
    margin-left: 0em;
    margin-right: 0em;
    background-color: rgba(255, 255, 255, .3);
}

.index-search-header-title {
    color: #003399;
    font-size: 175%;
    font-weight: bold;
    margin-bottom: 1rem;
}

.index-search-header-close {
    color: #003399;
    font-size: 150%;
    cursor: pointer;
}

.index-search-header-close :hover {
    color: #0056b3;
    cursor: pointer;
}

.index-search-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.index-top-message {
    color: #003399;
    background-color: lightgrey;
}

.index-top-message-link {
    color: #003399;
    font-weight: bold;
}

.index-no-product-image {
    max-height: 150px;
    max-width: 250px;
    width: auto;
    height: auto;
}

.index-section-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}
