
.seller-card {
    color: black;
    padding: 5px;
    width: 140px;
    border: 1px solid #003399;
    position: relative;
    overflow: hidden;
}

.seller-card-small {
    color: black;
    padding: 5px;
    width: 110px;
    position: relative;
    overflow: hidden;
}

.seller-card-image {
    width: 130px;
    height: 130px;
}

.seller-card-image-small {
    width: 100px;
    height: 100px;
}

.seller-card-body {
    color: black;
}

.seller-card-seller-name {
    color: black;
    font-family: sans-serif;
    font-size: smaller;
    margin-top: 5px;
    text-align: center;

}

.seller-card-corner-ribbon-right{
    width: 200px;
    background: firebrick;
    position: absolute;
    top: 15px;
    right: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    opacity: .75;
}
