
/*.dashboard-screen-card {*/
/*//box-shadow: none;*/
    /*border: lightgrey 1px solid;*/
    /*background-color: #f8f9fa!important;*/
    /*margin: 3px;*/
    /*margin-right: 2em;*/
/*}*/

/*.dashboard-screen-card:hover {*/
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
    /*background-color: #efefef !important;*/
/*}*/


.dashboard-screen-create-link {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    text-wrap: none;
}


.dashboard-screen-title {
    font-size: 150%;
    font-family: century-gothic, sans-serif;
    color: #003399;
}

.dashboard-screen-card-header {
    font-family: century-gothic, sans-serif;
    font-size: 18pt;
    color: #003399;
}

.dashboard-screen-card-deck {
}

.dashboard-screen-card {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    position: relative;
    overflow: hidden;
}


.dashboard-screen-card-pricing {
    font-family: century-gothic, sans-serif;
    text-align: center;
    color: #003399;
    font-size: larger;
    padding-bottom: 1rem;
}

.dashboard-screen-heading {
    font-family: century-gothic, sans-serif;
    font-size: 14pt;
    text-align: justify-all;
    padding: 1rem;
}

.dashboard-settings-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    background-color: white;
    padding: 1rem;
    border: solid 1px #003399;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-stats-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 3rem;
    background-color: white;
    border: solid 1px #003399;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-stats-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    min-height: 100px;
    padding: 1rem;
    text-align: center;
    color: #003399;
    align-items: center;
    justify-content: center;
}

.dashboard-stats-box-right-border {
    border-right: 1px solid #003399;
}

.dashboard-stats-box-left-border {
    border-left: 1px solid #003399;
}

.dashboard-stats-box-label {
    font-size: 75%;
    font-weight: bolder;
}

.dashboard-stats-box-value {
    font-size: 100%;
    font-weight: bolder;
}

.dashboard-current-stats-card {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    margin-bottom: 3rem;
    align-items: flex-start;
    justify-content: space-between;
    color: #003399;
}

.dashboard-current-stats-box {
    background-color: white;
    border: solid 1px #003399;
    width: 100%;
}

.dashboard-current-stats-box-right {
    margin-top: 1rem;
}

@media screen and (min-width: 768px) {
    .dashboard-stats-box-value {
        font-size: 125%;
    }
}

@media screen and (min-width: 1200px) {
    .dashboard-current-stats-box {
        width: 49%;
    }
    .dashboard-current-stats-box-right {
        margin-top: 0rem;
    }
    .dashboard-stats-box-value {
        font-size: 150%;
    }
}



.dashboard-ribbon{
    width: 210px;
    background: firebrick;
    position: absolute;
    top: 40px;
    left: -45px;
    text-align: center;
    line-height: 36px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .75;
    font-weight: bold;
}


