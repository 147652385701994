.queue-screen {
    font-family: century-gothic, sans-serif;
}



.queue-screen-products-area {
    width: 75%;
    padding: 5px;
}

.queue-screen-image-area {
    width: 25%;
    padding: 5px;
}

.queue-screen-members-area {
    margin-top: 0em;
}

.queue-screen-members-area a:hover{
    text-decoration: none !important;
}


/*------- LIVE PRODUCT ---------*/


.queue-screen-liveproduct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    border: 1px solid #003399;
    background-color: white;
    padding: 1rem;
    color: #003399;
    cursor: pointer;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}

.queue-screen-liveproduct-name {
    font-size: 125%;
    font-weight: bolder;
    margin-bottom: 1rem;
}

.queue-screen-liveproduct-description {
}

.queue-screen-liveproduct-product-area {
    width: 100%;
    padding: 5px;
}

.queue-screen-liveproduct-image-area {
    width: 75%;
    padding: 5px;
    align-self: center;
}

@media screen and (min-width: 768px) {
    .queue-screen-liveproduct-image-area {
        width: 25%;
        padding: 5px;
    }
    .queue-screen-liveproduct-product-area {
        width: 75%;
        padding: 5px;
    }

    .queue-screen-liveproduct {
        flex-direction: row;
    }

}

.queue-screen-liveproduct-image {
    width: 100%;
}

.queue-screen-liveproduct-price {
    align-self: flex-end;
    justify-self: flex-end;
    font-weight: bolder;
    text-align: left;
}






/*------- HEADER ---------*/

.queue-screen-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 0em;
    width: 100%;
    font-family: century-gothic, sans-serif;
    padding: 10px;
    color: #003399;
}

.queue-screen-header-info-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.queue-screen-header-info-queuename {
    font-size: 200%;
    width: 100%;

}

.queue-screen-header-info-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: #003399 2px solid;
    height: 100%;
    margin-left: 1em;
    padding-left: 1em;
    font-family: century-gothic, sans-serif;
    font-size: medium;
    white-space: pre-line;
}

.queue-screen-header-tags {
    justify-self: flex-end;
}

.queue-screen-header-action-button {
    margin-bottom: 2rem;
}

.queue-screen-header-image {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    object-fit: scale-down;

}

.queue-screen-viewer-image {
    object-fit: contain;
    max-height: 200px;
}

@media screen and (min-width: 768px) {
    .queue-screen-header {
        flex-direction: row;
    }

    .queue-screen-header-image {
        min-width: 20%;
        flex-direction: column;
        object-fit: unset;
        /*background-color: rgba(200, 100, 100, .4);*/
    }

    .queue-screen-viewer-image {
        object-fit: contain;
        max-height: 250px;
    }

    .queue-screen-header-info-area {
        max-width: 80%;
        min-width: 60%;
        /*background-color: rgba(100, 100, 100, .4);*/
    }
}

.queue-screen-corner-ribbon{
    width: 200px;
    background: mediumseagreen;
    position: absolute;
    top: 15px;
    left: -70px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: .90;
}

.queue-screen-upcoming-product-sales-title {
    /*color: #003399;*/
    color: black;
    font-size: 200%;
    margin-bottom: 5px;
}


/*d-flex flex-row justify-content-between bg-white border-dark*/