
.scrollingqueuelist {
}


.scrollingqueuelist-item {
    //box-shadow: none;
    border: lightgrey 1px solid;
    background-color: #f8f9fa!important;
    margin-right: 1em;
    margin-bottom: 1em;
}

.scrollingqueuelist-item:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: #efefef !important;

}

.scrollingqueuelist-header {
    align-items: center;
}

.scrollingqueuelist-title {
    align-items: center;
    padding-left: 5px;
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
}

.scrollingqueuelist-create-link {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    text-wrap: none;
}


.scrollingqueuelist-seemore {
    font-family: century-gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1em;
    justify-content: center;
    align-self: center;
}

.scrollingqueuelist-no-queues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #003399;
    padding: 2rem;
    background-color: white;
    color: #003399;
    width: 250px;
}

